"use client";
import { allHomes, menuItems } from "@/data/menu";
import { toggleMobileMenu } from "@/utlis/toggleMobileMenu";
import Image from "next/image";
import Link from "next/link";
import { useEffect, useState } from "react";
import LanguageSelect from "./components/LanguageSelect";
import { init_classic_menu_resize } from "@/utlis/menuToggle";
import { scrollToElement } from "@/utlis/scrollToElement";
export default function HeaderPreview() {
  const [menuOpen, setMenuOpen] = useState([-1, -1]);
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered1, setIsHovered1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);

  const toggleParent1 = (i) => {
    const tempMenuOpen = [...menuOpen];
    if (menuOpen[0] == i) {
      tempMenuOpen[0] = -1;
    } else {
      tempMenuOpen[0] = i;
    }
    setMenuOpen(tempMenuOpen);
  };
  const toggleParent2 = (i) => {
    const tempMenuOpen = [...menuOpen];
    if (menuOpen[1] == i) {
      tempMenuOpen[1] = -1;
    } else {
      tempMenuOpen[1] = i;
    }
    setMenuOpen(tempMenuOpen);
  };
  const toggleParent3 = (i) => {
    const tempMenuOpen = [...menuOpen];
    if (menuOpen[2] == i) {
      tempMenuOpen[2] = -1;
    } else {
      tempMenuOpen[2] = i;
    }
    setMenuOpen(tempMenuOpen);
  };

  useEffect(() => {
    setTimeout(() => {
      scrollToElement();
    }, 1000);
    init_classic_menu_resize();
    // window.addEventListener("scroll", addScrollspy);

    window.addEventListener("resize", init_classic_menu_resize);

    return () => {
      // window.removeEventListener("scroll", addScrollspy);
      window.removeEventListener("resize", init_classic_menu_resize);
    };
  }, []);

  const solutionsData = {
    'All Products': {
      title: "Leave Management",
      description: "Manage your inventory and warehouse operations efficiently.",
      image: " ",
      link: "/solutions",
      subItems: [
        {
          title: "Leave Management System",
          link: '/hrms-software/leave-management-system',
          subItems: [
            "Leave Policy & Allocation",
            "Leave History",
            "Leave Policy & Allocation",
            "Leave History",
            "Leave Policy & Allocation",
            "Leave History",
          ]
        },
        {
          title: "Employee Management",
          link: '/hrms-software/Employee-management-system',
          subItems: [
            "Employee View ",
            "Employee Update"
          ]
        },
        {
          title: "Attendance Management",
          link: '/hrms-software/Attendance-management-system',
          subItems: [
            "Attendance Policies",
            "Attendance Adjustment"
          ]
        }
      ]
    },
    'financial': {
      title: "CRM",
      description: "Track, nurture, and convert leads into customers with our comprehensive CRM solution, offering lead scoring, automation, and pipeline management.",
      image: "/assets/images/img/slides/crm_slide.webp",
      link: "/solutions",
      subItems: [
        {
          title: "CRM Management System",
          link: '/hrms-software/CRM-management-system',
          subItems: [
            "CRM Policy & Allocation",
            "CRM History",
            "CRM Policy & Allocation",
            "CRM History",
            "CRM Policy & Allocation",
            "CRM History",
          ]
        },
        {
          title: "Management",
          link: '/hrms-software/Employee-management-system',
          subItems: [
            "Employee View ",
            "Employee Update"
          ]
        },
        {
          title: "Content",
          link: '/hrms-software/Attendance-management-system',
          subItems: [
            "Attendance Policies",
            "Attendance Adjustment"
          ]
        }
      ]
    },
    'vehicle': {
      title: "Campaigns",
      description: "Create and manage email marketing campaigns, track performance, and automate follow-ups with our powerful email marketing tool.",
      image: "/assets/images/img/slides/campaigns_slide.webp",
      link: "/solutions",
      subItems: []
    },
    'inventory': {
      title: "HRMS",
      description: "GetBiz Solutions offers a powerful suite of products, including HRMS and CRM, to streamline your business operations. Enhance productivity and drive growth with our comprehensive tools.",
      image: "/assets/images/img/slides/hrms_slide.webp",
      link: "/solutions",
      subItems: []
    },
  };

  const [selectedSolution, setSelectedSolution] = useState(null);
  const [selectedSubItem, setSelectedSubItem] = useState(null);
  const handleMouseOver = (key) => {
    setSelectedSolution(solutionsData[key]);
    setSelectedSubItem(null);
  };
  const handleMouseOverSolution = (key) => {
    setSelectedSolution(solutionsData[key]);
    setSelectedSubItem(null);  // Reset sub-item selection when hovering over new solution
  };

  const handleMouseOverSubItem = (subItem) => {
    setSelectedSubItem(subItem);
  };

  useEffect(() => {
    setSelectedSolution(solutionsData['inventory']);
  }, []);

  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 430 && window.innerHeight <= 930);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);



  return (
    <div className="main-nav-sub full-wrapper " >
      {/* Logo  (* Add your text or image to the link tag. Use SVG or PNG image format. 
              If you use a PNG logo image, the image resolution must be equal 200% of the visible logo
              image size for support of retina screens. See details in the template documentation. *) */}
      <div className="nav-logo-wrap local-scroll"
      // style={{ marginLeft: isMobile ? '0px' : '280px' }}
      >
        <Link href={`/`} className="logo">
          <Image
            // src="/assets/images/logo-white.svg"
            src="/assets/images/img/logo/getbiz_main_logo.webp"
            alt="GetBiz Solutions Logo"
            width={205}
            height={34}
          />
        </Link>
      </div>
      {/* Mobile Menu Button */}
      <div
        onClick={toggleMobileMenu}
        className="mobile-nav"
        role="button"
        tabIndex={0}
      >
        <i className="mobile-nav-icon" />
        <span className="visually-hidden">Menu</span>
      </div>
      {/* Main Menu */}
      <div className="inner-nav desktop-nav">
        <ul className="clearlist local-scroll">

          {/* Item With Sub */}
          {/* {menuItems.slice(5, 8).map((item, index) => (
            <li
              className={menuOpen[0] == index + 1 ? "js-opened" : ""}
              key={index}
            >
              <a
                href="#"
                onClick={() => toggleParent1(index + 1)}
                className="mn-has-sub"
              >
                {item.title} <i className="mi-chevron-down" />
              </a>
              <ul
                className={`mn-sub mn-has-multi ${menuOpen[0] == index + 1 ? "mobile-sub-active" : ""
                  } `}
              >
                {item.subMenu.map((subItem, subIndex) => (
                  <li className="mn-sub-multi" key={subIndex}>
                    {subItem.title && (
                      <span className="mn-group-title">{subItem.title}</span>
                    )}
                    <ul>
                      {subItem.links.map((link, linkIndex) => (
                        <li key={linkIndex}>
                          <Link href={link.href}>
                            {link.icon && <i className={link.icon} />}{" "}
                            {link.text}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </li>
          ))} */}
          {/* End Item With Sub */}

          {/* my menu */}
          <li
            className={menuOpen[1] == 1 ? "js-opened" : ""}
          >
            <a
              href="#"
              onClick={() => toggleParent2(1)}
              className="mn-has-sub"
            >
              Products <i className="mi-chevron-down" />
            </a>
            {isMobile
              ?
              (
                // Mobile Menu
                <ul
                  className={`mn-sub mn-has-multi ${menuOpen[1] == 1 ? "mobile-sub-active" : ""
                    } `}
                >
                  {/* Column 1 */}
                  <li className="mn-sub-multi"

                    style={
                      !isMobile
                        ? {
                          maxWidth: '150px',
                          marginLeft: '-30%',
                          display: 'flex',
                          flexDirection: 'column'
                        }
                        : {}
                    }
                  >
                    <span className="mn-group-title">All Products</span>
                    <ul>
                      <li onMouseOver={() => handleMouseOver('inventory')}>
                        <a href="/hrms-software"
                          className="btn  btn-round mb-xs-10 hover-effect"
                          style={{
                            transition: 'box-shadow 0.3s ease',
                            borderBottom: '1px solid white',
                            boxShadow: 'none',
                            ':hover': {
                              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                            },
                            textAlign: 'left'
                          }}
                          onMouseEnter={() => setIsHovered1(true)}
                          onMouseLeave={() => setIsHovered1(false)}
                        >HRMS
                        </a>
                      </li>
                      <li onMouseOver={() => handleMouseOver('financial')} >
                        <a href="/"
                          style={{ borderBottom: '1px solid white' }}
                          className="btn  btn-round mb-xs-10 hover-effect"
                          onMouseEnter={() => setIsHovered2(true)}
                          onMouseLeave={() => setIsHovered2(false)}
                        >CMS</a>
                      </li>
                      <li onMouseOver={() => handleMouseOver('vehicle')}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                      >
                        <a href="/"
                          style={{ borderBottom: '1px solid white', color: 'white' }}
                          className="btn  !text-white btn-round mb-xs-10 "
                        >Campaigns
                        </a>

                      </li>
                      <li>
                        <a href="/"
                          className="btn  btn-round mb-xs-10 "
                        >&nbsp;</a>
                      </li>
                    </ul>
                  </li>
                </ul>

              )
              :
              (
                // Desktop Menu
                <ul
                  className={`mn-sub mn-has-multi ${menuOpen[1] == 1 ? "mobile-sub-active" : ""
                    } `}
                >
                  {/* Column 1 */}
                  <li className="mn-sub-multi"

                    style={
                      {
                        maxWidth: '150px',
                        marginLeft: '-30%',
                        display: 'flex',
                        flexDirection: 'column'
                      }
                    }
                  >
                    <span className="mn-group-title">All Products</span>
                    <ul>
                      <li onMouseOver={() => handleMouseOver('inventory')}>
                        <a href="/hrms-software"

                          className="btn btn-round mb-xs-10 hover-effect"
                          style={{
                            transition: 'box-shadow 0.3s ease',
                            borderBottom: '1px solid white',
                            boxShadow: 'none',
                            ':hover': {
                              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                            },
                            textAlign: 'left'
                          }}
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                        >HRMS&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          {isHovered ?
                            <i className="mi-arrow-right"
                              style={{
                                alignItems: 'right',
                                fontSize: '18px',
                                // display: 'flex',
                                justifyContent: 'flex-end'
                              }} />
                            :
                            ' '
                          }
                        </a>
                      </li>
                      <li onMouseOver={() => handleMouseOver('financial')} className="py-3"
                        onMouseEnter={() => setIsHovered1(true)}
                        onMouseLeave={() => setIsHovered1(false)}
                      >
                        <a href="/"
                          style={{ borderBottom: '1px solid white' }}
                          className="btn btn-round mb-xs-10 hover-effect"
                        >CMS&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          {isHovered1 ?
                            <i className="mi-arrow-right"
                              style={{
                                alignItems: 'right',
                                fontSize: '18px',
                                // display: 'flex',
                                justifyContent: 'flex-end'
                              }} />
                            :
                            ' '
                          }
                        </a>
                      </li>
                      <li onMouseOver={() => handleMouseOver('vehicle')}
                        onMouseEnter={() => setIsHovered2(true)}
                        onMouseLeave={() => setIsHovered2(false)}
                      >
                        <a href="/"
                          style={{ borderBottom: '1px solid white' }}
                          className="btn !text-white btn-round mb-xs-10 hover-effect"
                        >Campaigns&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                          {isHovered2 ?
                            <i className="mi-arrow-right" style={{ alignItems: 'right', fontSize: '16px' }} />
                            :
                            ' '
                          }
                        </a>

                      </li>
                      <li>
                        <a href="/"
                          className="btn  btn-round mb-xs-10 "
                        >&nbsp;</a>
                      </li>
                    </ul>
                  </li>

                  {/* Column 2 */}
                  <li className="mn-sub-multi"
                    style={{
                      minWidth: '350px',
                      marginLeft: '-30%',
                      display: 'flex',
                      flexDirection: 'column'
                    }}>
                    <span className="mn-group-title">&nbsp;</span>
                    <ul>
                      <li className='!min-w-[350px] !ml-[-30%] flex flex-col '>
                        {/* <img src={selectedSolution ? selectedSolution.image : '/assets/images/img/it_solutions_itsm.webp'}
                      className='pt-5 pb-2 rounded-md w-screen' alt={selectedSolution ? selectedSolution.title : 'Solution'} /> */}

                        <p className="menu-heading" style={{ color: 'var(--color-gray-light-5)' }}>
                          {selectedSolution ? selectedSolution.title : 'All Products'}
                        </p>
                        <p className="sec-text  pb-3 !min-w-fit" style={{ color: 'var(--color-gray-light-5)', textAlign: 'left', padding: "10px" }}>
                          {selectedSolution ? selectedSolution.description : 'Products Project Goals for Accurate Costs and Timely Completion Clarify Project Goals for Accurate Costs and Timely Completion Clarify Project Goals for Accurate Costs and Timely Completion'}
                        </p>
                      </li>
                    </ul>
                  </li>

                  {/* Column 2 */}
                  <li className="mn-sub-multi" style={{
                    minWidth: '350px',
                    marginLeft: '-30%',
                    display: 'flex',
                    flexDirection: 'column'
                  }}>
                    {/* <span className="mn-group-title">column 2</span> */}
                    <ul>
                      <li className='!min-w-[350px] !ml-[-30%] flex flex-col '>
                        <img src={selectedSolution ? selectedSolution.image : '/assets/images/img/it_solutions_itsm.webp'}
                          className='pt-5 pb-2 rounded-md w-screen' alt={selectedSolution ? selectedSolution.title : 'Solution'} />

                        {/* <a className="menu-heading text-black ">
                      {selectedSolution ? selectedSolution.title : 'Smart Solutions'}
                    </a>
                    <a className="sec-text text-black pb-3 !min-w-fit">
                      {selectedSolution ? selectedSolution.description : 'Clarify Project Goals for Accurate Costs and Timely Completion'}
                    </a> */}
                      </li>
                    </ul>
                  </li>

                </ul>

              )
            }

          </li>

          {/* Solutions only list  */}
          <li
            className={menuOpen[2] == 1 ? "js-opened" : ""}
          >
            <a
              href="#"
              onClick={() => toggleParent3(1)}
              className="mn-has-sub"
            >
              Solutions <i className="mi-chevron-down" />
            </a>
            <ul
              className={`mn-sub mn-has-multi ${menuOpen[2] == 1 ? "mobile-sub-active" : ""
                } `}
            >
              {/* Column 1 */}
              {/* <li className="mn-sub-multi" >
                <span className="mn-group-title">column 1</span>
                <ul> */}
              <li>
                <a href="/solutions/it-solutions/">
                  IT Solutions
                </a>
              </li>
              <li>
                <a href="/solutions/marketing-automation/">
                  Marketing Automation
                </a>
              </li>
              <li>
                <a href="/solutions/customer-service-solutions/">
                  Customer Service Solutions
                </a>
              </li>
              <li>
                <a href="/solutions/customer-relationship-management/">
                  Customer Relationship Management
                </a>
              </li>
              <li>
                <a href="/solutions/enterprise-service-management/">
                  Enterprise Service Management
                </a>
              </li>
              <li>
                <a href="/solutions/sales-automation/">
                  Sales Automation
                </a>
              </li>
              {/* <li>
                <Link href="/solutions/ca-firm/">
                  CA Firm
                </Link>
              </li>
              <li>
                <Link href="/solutions/cs-firm/">
                  CS Firm
                </Link>
              </li>
              <li>
                <Link href="/solutions/cpa-firm/">
                  CPA Firm
                </Link>
              </li>
              <li>
                <Link href="/solutions/law-firm/">
                  LAW Firm
                </Link>
              </li>
              <li>
                <Link href="/solutions/bpo-kpo/">
                  BPO/KPO
                </Link>
              </li>
              <li>
                <Link href="/solutions/real-estate/">
                  Real Estate
                </Link>
              </li> */}

              {/* </ul> */}
              {/* </li> */}
            </ul>
          </li>

          {/* About Us */}
          <li>
            <a href="/about">About Us</a>
          </li>

          {/* Pricing */}
          <li>
            <a href="/pricing">Pricing</a>
          </li>

          {/* Contact Us */}
          <li>
            <a href="/contactus">Contact Us</a>
          </li>

        </ul>
        <ul className="items-end clearlist">
          {/* Languages */}
          {/* <LanguageSelect /> */}
          {/* End Languages */}
          {/* <li>
            <a
              href="/signin"
              target="_blank"
              rel="nofollow noopener"
              className="opacity-1 no-hover"
            >
              <span className="link-hover-anim underline" data-link-animate="y">
                <span className="link-strong link-strong-unhovered">
                  Sign in
                </span>
                <span
                  className="link-strong link-strong-hovered"
                  aria-hidden="true"
                >
                  Sign in
                </span>
              </span>
            </a>
          </li> */}
          <li>
            <a href="/book-demo" className="opacity-1  contact-link"
            // onMouseEnter={() => setIsHovered(true)}
            // onMouseLeave={() => setIsHovered(false)}
            >
              <span className="btn btn-mod btn-border-grad btn-small btn-round"
                style={{
                  transition: 'box-shadow 0.3s ease',
                  boxShadow: isHovered ? '0 4px 8px rgba(0, 0, 0, 0.3)' : 'none',
                }}>
                <span data-btn-animate="y">Book Demo</span>
              </span>
            </a>
          </li>
        </ul>
      </div>
      {/* End Main Menu */}
    </div>
  );
}
